<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Countries</div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | formatDate }}</div>
              </template>
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";

export default {
  data: () => ({
    isLoading: false,
    pagination: {
      current_page: 1,
    },
    headers: [
      {
        text: "COUNTRIES",
        align: "start",
        value: "name_en",
        sortable: false,
      },
      { text: "ISO", value: "ISO" },
      { text: "CREATE DATE", value: "created_at" },
    ],
    listData: [],
  }),
  components: { EmptyState },
  methods: {
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      apiServices.get(`countries?per_page=7&page=${page}`).then((res) => {
        if (res) {
          this.listData = res.data;
          this.pagination = res.meta;
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
  },
  created() {
    this.getListData(this.pagination.current_page);
  },
};
</script>
<style lang="scss" scoped>
@import "./_countries.scss";
</style>
